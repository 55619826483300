code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

strong {
  font-weight: 700 !important;
}

.card {
  border: none;
  /* -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%); */
  /* box-shadow: 0 0.3rem 0.3rem rgb(0 0 0 / 15%); */
}

.detailscard {
  margin-top: 10px;
}

.detailscard {
  width: 100%;
  background: white;
  border: 1px solid lightgray;
  padding: 0px;
}

.detailscard2 {
  margin-top: 10px;
}

.detailscard2 {
  background: #fafafa;
  width: 100%;
  border: 1px solid lightgray;
  padding: 0px;
}

.detailscard2 table {
  background: #dcf1e4;
}

.detailscard2.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
  border: 1px solid lightgray;
}

.detailscard.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
  border: 1px solid lightgray;
}

li.list-group-item.detailspage {
  position: relative;
  display: block;
  padding: 0px !important;
  background-color: #fff;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
}

.selectstock {
  position: absolute;
  right: 10px;
}

.selectStock2 {
  position: absolute;
  right: 170px;
}

.selectStock3 {
  position: absolute;
  left: 140px;
}

/* //////////Select address/////////////// */

.section-title {
  /* text-transform: capitalize; */
  font-size: 25px;
  position: relative;
  padding-bottom: 0px;
}

.new-address-btn {
  background: #ee1b2f;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 4px 9px;
  border-radius: 3px;
  float: right;
  margin: auto;
  box-shadow: 2px 2px 10px rgb(10 10 10 / 11%);
}

.address-header h5 {
  font-size: 16px;
  margin-bottom: 15px;
}

.address-header {
  display: flex;
  align-items: baseline;
}

.address-header input {
  margin-right: 10px;
}

.checkbox input {
  margin-right: 10px;
}

.address-links {
  margin-top: 10px;
}

.address-links a {
  margin-right: 10px;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: transparent !important;
  border: none !important;
}

.checkout-bx {
  padding: 15px 0;
  border-bottom: 1px solid #f9f6f6;
}

.checkout-bx:last-child {
  border-bottom: none;
}

.checkout-img {
  height: 75px;
  width: 75px;
  border-radius: 3px;
}

.checkout-details p {
  font-size: 20px;
}

.checkout-details h6 {
  margin-bottom: 5px;
  margin-top: 15px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.grand-total {
  font-size: 17px;
}

.grand-total td {
  padding-top: 20px !important;
  font-weight: 300;
}

.checkout-btn {
  background: #ee1b2f;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  display: inline-block;
  box-shadow: 2px 2px 10px rgb(10 10 10 / 11%);
  border-radius: 3px;
}

.checkout-btn:hover {
  color: #ee1b2f;
  background: white;
  border: 1px #ee1b2f solid;
  border-radius: 3px;
}

.boxes {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #eeeeee;

  border-width: 1px;
  box-sizing: border-box;
  border-radius: 5px;
}

.address-box {
  font-size: 14px;
}

.address-box input {
  margin-right: 10px;
}

.order-summary {
  /* border: 1px solid #EAEAEA; */
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 45px;
  border-radius: 3px;
  /* background: #F7F7F7; */
  border: none;
  border: 1px solid #eeeeee;
}

.order-summary h5 {
  font-size: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.checkout-box {
  border: none;
}

.btnicons {
  width: 20px;
}

.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 60px;
  height: 96%;
}

.address-card {
  width: 100%;
  height: 100%;

  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}

/* //////////Select address end /////////////// */

/* ===========Login.js=========== */

.rgbox-bottom {
  position: relative;
}

.rgbox-bottom p::before {
  border-bottom: 1px solid #767676;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 10px;
  margin: 0 auto;
}

.rgbox-bottom p label {
  background-color: #fff;
  margin-bottom: 0px;
  color: #767676;
  position: relative;
  padding: 0px 5px;
}

.but button {
  margin-top: 10px;
  width: 100%;
}

.cart-box .account-dropdown-content {
  position: absolute;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  min-width: 180px;
  box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 20px 20px 20px 30px;
  z-index: 9999;
  margin-top: 15px;
  transform: translateY(-2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.but-button {
  margin-top: 10px;
  width: 100%;
  background: white;
  color: #50b05d;
  border: 1px solid #50b05d;
}

.but-button :hover {
  background: #50b05d !important;
  color: white !important;
  border: 1px solid #50b05d !important;
}

/* ===========Login.js end=========== */

.header-hover:hover {
  color: #50b05d;
  text-decoration: none;
}

.detailscard .card-body:hover {
  box-shadow: 10px 10px 15px 0 rgb(7 28 31 / 10%);
}

.tab {
  overflow: hidden;
  border: 1px solid #fff;
  margin-top: 10px;
  border-top-left-radius: 10px;
  margin-left: 20px;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button {
  background-color: #0f3d74;
  color: white;
  border-top-right-radius: 10px;
  height: 40px;
}

.footercart {
  width: 325px;
  /* border: 1px solid #f7f7f7; */
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: none;
  margin-left: 5px;
}

.footercart img {
  transition: 0.5s all;
}

.footercart:hover img {
  transform: scale(1.05) translateY(5px);
}

.footerCard {
  display: flex;
}

.CustomerPara {
  margin-top: 10px;
  margin-left: 2px;
}

.securepay {
  margin-left: 22px;
}

.footer-section {
  margin-bottom: 40px;
  margin-top: 40px;
}

.CustomerPara span {
  font-weight: 300;
}

.footer-section2 {
  margin-bottom: 25px;
}

@media screen and (min-device-width: 481px) and (max-device-width: 731px) {

  /* =======footer responsive== */
  .footercart {
    margin-bottom: 10px;
  }

  .footerCard img {
    width: 30%;
    height: 80px;
    margin-top: 10px;
  }

  .securepay {
    margin-left: 5px !important;
  }
}

@media screen and (max-width: 1024px) {
  .footerCard {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .footerCard img {
    margin: 0;
  }

  .footerCard .CustomerPara {
    margin: 0;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .footercart {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .img-fluid {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 0 !important;
  }

  .image-Grid img {
    margin: 0 !important;
  }

  .footer .add {
    width: 100% !important;
  }

  .follow-us-section {
    padding-top: 80px;
    width: 100% !important;
  }

  .follow-us-section .contact-list {
    margin: 0 !important;
    margin-bottom: 15px !important;
  }
}

@media (max-width: 1024px) {
  .footerfollowus {
    flex-direction: column !important;
  }

  .footerfollowus .social-icons {
    margin: 0 !important;
  }

  .footer-margin {
    /* margin-left: 7px; */
    margin-bottom: -5px !important;
  }
}

@media (max-width: 575.98px) {
  .footerCard img {
    width: 30% !important;
    height: 30% !important;
    margin-top: 10px !important;
  }

  .follow-us-section {
    padding-top: 0;
    width: 100% !important;
  }
}

.coupon {
  border: 1px dashed #a9abb2;
  color: #535665;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;
  margin: 10px 0 15px;
  font-weight: 400;
  height: 50px;
}

/* By Vinay Maheshwari */
.coupon {
  border: 1px dashed #a9abb2;
  color: #535665;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;
  margin: 10px 0 15px;
  font-weight: 400;
  height: 50px;
}

.coupon-card {
  padding: 25px 40px;
  border: 1px solid #e9e9eb;
  color: #3e4152;
}

.modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 3rem;
  border-bottom: none !important;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 3rem;
}

.close {
  float: right;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.coupon-more {
  color: #5d8ed5;
  font-size: 1rem;
  cursor: pointer;
}

.apply-coupon-btn {
  width: 100%;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border: none;
  line-height: 50px;
  font-size: 15px;
  height: 50px;
  padding: 0 2rem;
  color: #0F3D74;
  letter-spacing: 0;
  border: 1px solid #0F3D74;
  margin-top: 20px;
  line-height: 36px;
  width: auto;
  height: 42px;
  padding: 0 15px;
  outline: none;
  min-width: 120px;
  background: #fff;
}

button:focus {
  outline: none;
  outline: none;
}

.coupon-card h5 {
  background-color: wheat;
  width: 50%;
  padding: 10px 20px;
  text-align: center;
  font-weight: 400;
}

.coupon-terms-condition {
  font-size: 0.92rem;
  color: #686b78;
}

.removeCoupon {
  float: right;
}

.removeCoupon :hover {
  color: #ee1b2f !important;
}

.coupon-text span {
  color: green;
}


.MobileBannerTextVinay {
  position: absolute;
  top: 45%;
  padding-left: 20px;
}


button.btn.btn-success.mobilebannerinnerbtn4 {
  width: 96px !important;
  background: rgb(0, 0, 0) !important;
  border: #000000 !important;
  font-size: 15px !important;
  border-radius: 5px;
}

/* Product image size */
.product-img-size,
.best-seller-image-size {
  /* height: 350px; */
  height: auto;
}

/* .category-image-size{
  height: 260px;
} */

@media (max-width:600px) {

  .product-img-size,
  .best-seller-image-size,
  .category-image-size {
    height: auto;
  }
}


/* New css added for Categories heading */
.category-heading-font {
  color: #0F3D74;
  font-size: 28px;
  font-weight: 400
}

@media (max-width:570px) {
  .section-title h4 {
    font-size: 22px;
    color: #0f3d74;
    font-weight: 400;
    text-align: left;
  }

  .category-heading-font {
    color: #0F3D74;
    font-size: 22px;
    font-weight: 400
  }

  .product-slider-dot .slick-dots {
    display: none !important;
  }
}


/* **** Common space for products phone view ***** */
@media (max-width:500px) {
  .extra-space-phn {
    /* padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important; */
  }

  .product-box-outer {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .phn-view-margin-cetegory {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width:460px) {
  .product-box-outer {
    margin-right: 3px !important;
    margin-left: 0 !important;
  }
}

/* ***** Navbar ***** */
@media (max-width:1265px) {
  .nav-link-font {
    transition: none;
    border-radius: 10px;
    border: 1px solid transparent;
    padding: 7px 5px 2px 5px !important;
    font-family: 'Bebas Neue';
  }

  /* .nav-link-font p {
    font-size: 20px !important;
  } */
  .nav-link-font p {
    font-size: 18px !important;
  }

  .header-contact-font {
    color: #212529;
    font-size: 15px;
  }
}

@media (max-width:1265px) and (min-width:1060px) {
  .header-email-icon-font {
    font-size: 18px !important;
    margin-left: 10px;
    margin-right: 10px;
    color: #212529;
    margin-top: 3px !important;
  }

  .header-contact-icon-font {
    font-size: 18px !important;
    margin-right: 10px;
    color: #212529;
    margin-top: 5px !important;
    margin-left: 10px;
  }
}

@media (min-width:1060px) {
  .header-contact-icon-font {
    font-size: 25px;
    margin-right: 10px;
    color: #212529;
    margin-top: 2px;
    margin-left: 10px;
  }

  .header-contact-font {
    color: #212529;
    font-size: 17px;
  }

  .header-email-icon-font {
    font-size: 25px;
    margin-left: 10px;
    margin-right: 10px;
    color: #212529;
    margin-top: 1px;
  }
}

@media (max-width:1060px) {
  .header-contact-icon-font {
    font-size: 20px;
    margin-right: 10px;
    color: #212529;
    margin-top: 4px;
    margin-left: 10px;
  }

  .header-contact-font {
    color: #212529;
    font-size: 14px;
  }

  .header-email-icon-font {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    color: #212529;
    margin-top: 3px;
  }
}

@media (max-width:400px) {
  .blog-phn-view {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .product-phn-view-bottom {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width: 340px) {
  .section-title h4 {
    font-size: 17px !important;
    color: #0f3d74;
    font-weight: 400;
    text-align: left;
  }

  .card-title.blogtitle {
    font-size: 17px !important;
  }

  .blog-text-p {
    font-size: 16px !important;
  }

  .thm-btn {
    font-size: 15px !important;
  }

  .extra-space-phn {
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.social-icon-sidebar {
  font-size: 45px !important;
}

/* ******* Banner craousel arrow Css ********** */
.homebanner .slick-prev,
.homebanner .slick-next {
  font-size: 30px !important;
  color: transparent !important;
  line-height: 0;
  position: absolute;
  top: 93%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent !important;
}

.homebanner .slick-next:before,
.homebanner .slick-prev:before {
  font-size: 30px !important;
  line-height: 1;
  opacity: .75;
  color: #fff !important;
  padding: 5px 10px;
  background: transparent !important;
  border-radius: 5px;
}

.homebanner .slick-next {
  right: 47% !important;
}

.homebanner .slick-prev {
  left: 47% !important;
  z-index: 2;
}

@media (max-width:1040px) {

  .homebanner .slick-prev,
  .homebanner .slick-next {
    font-size: 30px !important;
    color: transparent !important;
    line-height: 0;
    position: absolute;
    top: 90%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent !important;
  }
}

.free-item {
  font-size: 20px;
}

.free-item-cart {
  text-align: end !important;
  font-size: 20px;
}

.free-item-customer-history {
  height: 58px;
  font-size: 20px;
}

.home-popup-model .modal-content {
  margin: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}



/* Only for sppiner */
.home-popup-model .modal-content {
  background: transparent !important;
  border: none !important;
}

.wheel-modal div {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

.spin-btn {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  top: 46%;
  background: #0f3d74;
  color: white;
  border: none;
  z-index: 100 !important;
}

.session-coupon-btn {
  background-color: wheat;
  padding: 10px 20px;
  text-align: center;
  font-weight: 400;
}
.seesion-coupon-main-box{
  justify-content: space-between;
  align-items: center;
}
.session-cookie-coupon{
  height: 90px;
}
.session-coupon-weight{
  font-weight: 700;
}
.winning-coupon{
  font-weight: 500 !important;
}
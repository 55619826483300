/* .App {
    text-align: center;
  }
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
  .App-header {
    background-color: #282C34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .App-link {
    color: #61DAFB;
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */

.search-result ul .list-group-item {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.search-result .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.25rem;
  height: 250px;
  overflow-y: auto;
  overflow-x: none;
}
.search-result .list-group-item h5 {
  font-size: 12px;
  margin-right: 15px;
}
td {
  list-style: none;
  padding: 15px 15px 10px 10px;
  border-bottom: 1px solid #ccc;
  width: auto;
  cursor: pointer;
  align-items: center;
}
td img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#query {
  width: 100%;
  font-size: 15px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
#notebooks {
  background: white;
  position: relative;

  margin-top: 25px;

  border-radius: 0.3rem;
  box-shadow: inset 1px 1px 0 white;

  z-index: 999;
}

tbody {
  margin: 0 auto;
  padding: 0;
  max-height: 390px;
  overflow-y: auto;
  border-left: none;
  border-right: none;
}
#notebooks span {
  display: block;
  position: flex;
  background: #0f3d74;
  bottom: -35px;
  width: 433px;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 1px rgba(255, 255, 255, 0.5);
}

.search-result .card-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.search-result .card-body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.search-result .card-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0f3d74;
}
.search-result {
  position: absolute;
  top: 3rem;
  top: 3rem;
  left: 0;
  right: 0;
}

.input-menu-all {
  display: none;
}
.open-menu-all {
  position: relative;
  margin: 0;
}
.all-category-list {
  position: absolute;
  z-index: 9999;
  display: none;
  width: 250px;
}
.open-menu-all .input-menu-all:checked ~ .all-category-list {
  display: block;
  transition: all 0.5s ease;
}
.nav-container .nav {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.category {
  margin: 0 20px;
}
.open-search {
  flex: auto;
  margin: 0 20px;
}
.mob-logo {
  display: none;
}
.input-open-search {
  display: none;
}
.open-search i {
  display: none;
}
@media (max-width: 991px) {
  .all-category-list {
    position: absolute;
    display: block;
    z-index: 9999;
    left: -280px;
    width: 250px;
  }
  .open-menu-all .input-menu-all:checked ~ .all-category-list {
    display: block;
    left: -20px;
    transition: all 0.5s ease;
  }
  .open-menu-all .input-menu-all:checked ~ .categories-btn .fa-bars:before {
    content: "\f00d";
  }
  .categories-btn span {
    display: none;
  }
  .mob-logo {
    display: block;
  }
  .open-search i {
    display: block;
  }

  .logo1 {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  #query {
    padding: 5px;
  }
  .category {
    margin: 0;
    order: -1;
  }
  .open-search .input-open-search:checked ~ .search-box1 {
    display: block;
  }
  .categories-btn {
    background: #0f3d74;
    color: #fff;
    width: 100%;
    display: block;
    text-align: left;
    padding: 10px 15px;
  }
  .open-search {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
  }
  .search-box1 {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 59px;
  }
  .open-search i {
    font-size: 25px;
  }
  .account-icon {
    font-size: 30px !important;
    border: 1px solid #000000;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
  .account-options li {
    list-style-type: none;
    margin-right: 23px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .categories-btn i {
    margin-right: 0px;
    width: 15px;
    height: 15px;
  }
  .user-login {
    display: none;
  }
}
.searchProduct {
  margin-left: 15px;
  margin-top: 15px;
}
.Search_variantName {
  font-size: 13px;
  line-height: 1.5;
  font-weight: 300;
}
.search-btn {
  position: absolute;
  right: 10px;
}

p.searchPriceinfo {
  position: absolute;
  right: 207px;
}
.search-weight {
  position: absolute;
  right: 350px;
  top: 30px;
}

.whatsapp-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: #fff;
  position: fixed;
  bottom: 40px;
  text-align: center;
  line-height: 55px;
  font-size: 30px;
  right: 20px;
  z-index: 999;
}
.whatsapp-icon a,
.call-icon a {
  color: #fff;
}
.call-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: #fff;
  position: fixed;
  bottom: 130px;
  text-align: center;
  line-height: 55px;
  font-size: 30px;
  right: 15px;
  z-index: 999;
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
@keyframes pulse-2 {
  0% {
    transform: scale(5);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: scale(7);
    opacity: 0;
  }
}
.pulse-bg {
  background-color: #25d366;
  z-index: -1;
  opacity: 1;
  animation: pulse 2s ease-in infinite;
  bottom: 10px;
  right: 15px;
}
.pulse-bg-2 {
  background-color: #25d366;
  z-index: -1;
  opacity: 1;
  animation: pulse 2s ease-out infinite;
  bottom: 10px;
  right: 15px;
}
.pulse-bg-blue {
  background-color: #24a0ed;
  z-index: -1;
  opacity: 1;
  animation: pulse 2s ease-in infinite;
  bottom: 10px;
  right: 15px;
}
.pulse-bg-2-blue {
  background-color: #24a0ed;
  z-index: -1;
  opacity: 1;
  animation: pulse 2s ease-out infinite;
  bottom: 10px;
  right: 15px;
}
